<template>
  <div class="the_sidebar">
    <div class="side_header" />
    <div class="side_menu_container">
      <menu-element
        :menu="$t('home')"
        icon="ic-accueil-ads"
        path="dashboard"
      />
      <menu-element
        :menu="$t('campaigns')"
        icon="ic-campaign"
        path="cards"
      />
      <menu-element
        :menu="$t('saveAudience')"
        icon="ic_audience"
        path="audience"
      />
      <!--- HIDEN WALLET AND NOTIFICATION OPTION IN MENU -->
      <!-- <menu-element
        :menu="$t('wallet')"
        icon="ic-wallet"
        path="histories"
        :is-wallet="true"
      /> -->
      <!-- <menu-element
        :menu="$t('notification')"
        icon="ic-notification"
        path="requests"
        :isNotif="true"
      /> -->
      <menu-element
        :menu="$t('contact')"
        icon="ic-contact"
        path="collaborators"
      />
      <!--<menu-element
        v-if="isAdmin"
        menu="Recharge"
        icon="ic-wallet"
        path="code"
      />-->
      <!--      <menu-element menu="Paramètres" icon="settings" path="settings"></menu-element>-->
    </div>
    <div class="side_footer">
      <div class="menu_bottom_container flex flex-col mx-16">
        <!-- <router-link class="menu_bottom_element" to="/">
          <svg-icon name="litee-logo-full" class="litee-logo w-32 h-10" original/>
        </router-link> -->
        <router-link
          class="menu_bottom_element invisible"
          to="/policies/terms"
        >
          Conditions générales
        </router-link>
        <router-link
          class="menu_bottom_element invisible"
          to="/policies/privacy"
        >
          Termes - Confidentialité
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import menuElement from '@/components/helper/side-menu-element'
import firebase from 'firebase'
import { firebaseRef } from '../rema-function/firebaseRef'
import { firebaseDb } from '../main'

export default {
  name: 'TheSidebar',
  components: {
    menuElement
  },
  data () {
    return {
      wallet: {}
    }
  },
  firebase () {
    return {
      wallet: firebaseDb.ref(firebaseRef.wallets).child(firebase.auth().currentUser.uid)
    }
  },
  computed: {
    isAdmin () {
      if (firebase.auth().currentUser.uid === 'TML3iJYvMlU5b1ckLkBgZ9KLa7u2') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/style/sass/variables';
.the_sidebar{
  border: solid 1px $border_gray;
  width: 21rem;
  height: 100%;
  position: relative;
  background-color: $white;
  .side_header{
    .info_compte{
      padding: 3rem 0 0 0;
      .solde_info{
          font-weight: 600;
          padding: 2rem 1rem;
          margin: 2rem;
          background-color: $beige;
          border-radius: 13px;
        .compte_selector{
          color: $light_gray;
        }
        .account_amount{
          color: $green;
        }
      }
    }
  }
  .side_menu_container{
    margin-top: 4.5rem;
    height: calc(100% - 10rem);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .side_footer{
    position: absolute;
    bottom: 0;
    margin-bottom: 1.2rem;
    width: 100%;
    .menu_bottom_container{
      .menu_bottom_element{
        color: $gray;
        font-size: 1rem;
        margin: .35rem 0;
      }
    }
  }
}
</style>
